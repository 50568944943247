<template>
    <div :class="['notify',this.$store.state.notify.type]">
        <div class="close" @click="close">+</div>
        <div class="title">{{ this.$store.state.notify.title }}</div>
        <div class="content">{{ this.$store.state.notify.content }}</div>
        <div class="progress">
            <div class="fill"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "notify",
    data() {
        return {
            showTime: 4000,
            timeout: false,
        }
    },
    created() {
        const subscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'showNotify') {
                let el = this.$el
                setTimeout(() => {
                    el.classList.add('opened');
                }, 1);
                this.$data.timeout = setTimeout(() => {
                    el.classList.remove('opened');
                }, this.$data.showTime)
            }
        })
    },
    methods: {
        close() {
            let el = this.$el
            clearTimeout(this.$data.timeout);
            el.classList.remove('opened');
        }
    }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/variables';
.notify {
    top: -200px;
    position: fixed;
    right: 20px;
    padding: 20px 40px 25px;
    min-width: 300px;
    max-width: 600px;
    transition: .3s linear;
    background: $cl-dark;
    color: #fff;
    .title {
        font-size: 20px;
        margin: 0 0 10px;
    }
    .close {
        position: absolute;
        right: 10px;
        top: 5px;
        transform: rotate(45deg);
        font-size: 20px;
        cursor: pointer;
    }
    .progress {
        position: absolute;
        bottom: 1px;
        left: 0;
        right: 0;
        .fill {
            height: 4px;
            width: 0;
            background: #fff;
            transition: 3.5s linear;
        }
    }
    &.opened {
        top: 20px;
        .progress {
            .fill {
                width: 100%;
            }
        }
    }
    &.success {
        background: $cl-green;
    }
    &.danger {
        background: $cl-red;
    }
    &.warning {
        background: $cl-yellow;
        color: $cl-dark;
    }
    &.info {
        background: $cl-cyan;
        color: $cl-dark
    }
}
</style>